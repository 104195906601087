span {
  font-weight: 400;
}

.terms-page-bold {
  font-weight: 700;
}

.c2 {
  font-weight: 700;
}
